/* ##################### */
/*    HTML variables     */
/* ##################### */
html {
    --dark-blue-color: #232163;
    --dark-blue-color-tranparent: #232163d0;
    --flashy-blue-color: #C9EDED;
    --background-color-app: #b2ecfa;
}

/* ##################### */
/*   Footer and Social Media  */
/* ##################### */

.footer {
    flex-shrink: 0;
    text-align: center !important;
    bottom: 0;
    background-color: var(--dark-blue-color);
    /* background-color: tomato; */
    color: white;
}

.footer-col {
    margin-top: 20px;
}

@media (max-width: 750px) {
    .footer-col {
        margin-top: 0%;
    }
}

.social-links {
    justify-content: center !important;
    /* padding-top: 15px !important; */
    display: inline-block !important;
    position: relative !important;
    padding-inline-start: 0 !important;
}

.social-icons {
    display: inline-block !important;
    padding-left: 15px;
    height: 60px;
}

.social-icon-hover:hover {
    color: white;
    box-shadow: 0 0 5px blue;
    text-shadow: 0 0 2px blue;
}

.social-icon-hover:hover::before {
    transform: scale(1.1);
    box-shadow: 0 0 5px blue;
}

.social-icon-color {
    color: white !important;
}

.social-single-icon {
    margin-top: 10px;
}


/* ##################### */
/*    Work In Progress   */
/* ##################### */

.workInProgress {
    text-align: center;
    margin-top: 200px !important;
    margin-bottom: 150px !important;
}

.workInProgress-title {
    font-size: 3rem !important;
    font-weight: bold !important;
}

.workInProgress-page-not-found {
    font-size: 4rem !important;
    font-weight: bold !important;
}

.workInProgress-description {
    padding-top: 20px;
    padding-left: 20%;
    padding-right: 20%;
}

/* ##################### */
/*     Navbar            */
/* ##################### */

.navbar {
    background-color: var(--dark-blue-color);
    position: fixed !important;
    padding: 0.3rem 2rem !important;
    font-size: 1.2rem !important;
    transition: all 0.3s ease-out 0s !important;
}

.navbar-expanded {
    background-color: var(--dark-blue-color-tranparent) !important;
    transition: all 0.3s ease-out 0s !important;
    box-shadow: 0px 10px 10px 0px rgba(10, 5, 30, 0.180) !important;
    backdrop-filter: blur(15px) !important;
}

/* ##################### */
/*     Home              */
/* ##################### */

.home-section {
    position: relative;
    background-position: top center;
    background-repeat: no-repeat;
    padding-bottom: 30px !important;
    padding-top: 30px !important;
}

/*--------*/
/* Home Main title section*/
/*--------*/

.home-title {
    padding: 1rem 0 0rem !important;
    /* color: white; */
    text-align: left;
}

.home-title-header {
    padding-top: 80px !important;
}

.home-title-job {
    font-size: 2.4em !important;
    padding-left: 20px !important;
}

.home-title-name {
    font-size: 2.6em !important;
    padding-left: 20px !important;
    margin-bottom: 20px !important;
}

.home-title-main-name {
    /* color: aliceblue; */
}

.home-title-description {
    margin-left: 20px !important;
}

.home-title-image {
    border-radius: 60%;
    height: 70%;
    width: 65%;
    margin-top: 12%;
    margin-left: 25%;
}

@media (max-width: 750px) {
    .home-title-image {
        border-radius: 50%;
        height: 80%;
        width: 50%;
        margin-top: 12%;
        margin-left: 25%;
    }
}

/*--------*/
/* Home Portfolio section*/
/*--------*/

.home-portfolio {
    position: relative !important;
    padding-top: 90px !important;
    padding-bottom: 30px !important;
    /* background-image: var(--section-background-color) !important; */
}

.home-portfolio-title {
    /* color: white !important; */
    font-size: 2.3em !important;
    font-weight: bold !important;
    padding-top: 10px !important;
    padding-bottom: 20px !important;
    text-align: center !important;
}

.home-portfolio-description {
    font-size: 1.3em !important;
    font-weight: bold !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    text-align: center !important;
}

.home-portfolio-card {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    height: auto !important;
}

/*--------*/
/* Home Skills section*/
/*--------*/

.home-skills {
    position: relative !important;
    padding-top: 20px !important;
    padding-bottom: 70px !important;
}

.home-skills-show {
    flex-direction: row;
}

@media (max-width: 750px) {
    .home-skills-show {
        flex-direction: column;
    }
}

.home-skills-show-col {
    border-right: 1px solid var(--dark-blue-color);
    box-shadow: 0 4px 5px 3px var(--dark-blue-color-tranparent) !important;
    background-color: var(--flashy-blue-color) !important;
    opacity: 0.9 !important;
    transition: all 0.5s ease 0s !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
}

.home-skill-tile {
    color: var(--dark-blue-color) !important;
    font-size: 2.3em !important;
    font-weight: 500 !important;
    padding-top: 10px !important;
    padding-bottom: 15px;
    text-align: center !important;
}

.home-skills-description {
    font-size: 1.1em !important;
    font-weight: 600 !important;
    /* padding-top: 10px !important; */
    padding-bottom: 30px !important;
    text-align: center !important;
}

/* ##################### */
/*     Portfolio         */
/* ##################### */

.card-project-simple {
    box-shadow: 0 4px 5px 3px var(--dark-blue-color) !important;
    color: black !important;
    /* background-color: #81D5CD !important; */
    /* background-color: #8898D7 !important; */
    background-color: var(--flashy-blue-color) !important;
    opacity: 0.9 !important;
    transition: all 0.5s ease 0s !important;
    height: 100% !important;
}

.card-project-simple:hover {
    transform: scale(1.02) !important;
    overflow: hidden !important;
    box-shadow: 0 4px 4px 5px #343e47 !important;
}

.card-project-simple-techno {
    color: black !important;
    font-weight: bold;
}

.card-project-button {
    background-color: var(--dark-blue-color) !important;
}

.card-project-button:hover {
    background-color: var(--background-color-app) !important;
}

/* ##################### */
/*   Profile             */
/* ##################### */

.card-about {
    border: none !important;
    /* color: var(--dark-blue-color) !important; */
    background-color: transparent !important;
    margin-top: 60px;
    margin-left: 20% !important;
    margin-right: 20% !important;
}

.card-about-activity {
    list-style: none !important;
    text-align: left !important;
    padding-left: 1px !important;
}

.card-about-myname {
    font-size: 1.5rem !important;
}